//
// Range
// --------------------------------------------------

.rangeslider {
  background-color: rgba(67, 41, 87, 0.15);
  .box-shadow(none);
  .rangeslider__fill {
    display: none;
  }
  .rangeslider__handle {
    background: @color-primary;
    &:after {
      background-color: @color-secondary;
      background-image: none;
    }
  }
}

.range-values {
  .flex-display(flex);
  .flex-justify-content(space-between);
  margin-top: 5px;
  span {
    .fontBold;
  }
}