//
// Footer
// --------------------------------------------------

#footer {
  background-color: @color-primary;
  color: @white;
  padding: 20px 0;
  p {
    margin-bottom: 0;
  }
}