//
// Forms
// --------------------------------------------------

.formStyles {
  // Global
  .form-item,
  .form-actions {
    margin: 0 0 @base-line-height;
  }
  label {
    display: block;
    .fontBold;
    margin-bottom: 3px;
  }
  .description {
    color: @gray;
    margin-top: 3px;
  }
  .grippie {
    display: none;
  }

  // Inputs
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="number"],
  input[type="date"],
  textarea {
    background-color: @color-tertiary;
    border: none;
    color: @color-quaternary;
    display: block;
    font-size: @base-font-size;
    height: 43px;
    line-height: @base-line-height;
    padding: 6px 18px;
    width: 100%;
    .border-radius(10px);
    .box-shadow(1px 2px 5px 0px rgba(0, 0, 0, 0.1) inset);
    .transition(all .2s ease);
    -webkit-appearance: none;
    &:focus {
      background-color: #fef6ed;
      outline: 0;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #aaaaaa;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #aaaaaa;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #aaaaaa;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #aaaaaa;
    }
  }
  textarea {
    height: auto;
  }
  select, select option {
    color: @black;
  }
  select {
    width: 100%;
  }

  // Radio buttons
  .form-radios {
    // Two items (button-like)
    &.form-radios-2 {
      .form-item {
        margin-bottom: 10px;
      }
      input[type="radio"] {
        display: none;
        + label {
          background-color: #362346;
          color: @white;
          display: block;
          font-size: 1.1764705882rem;
          margin-bottom: 0;
          padding: 5px;
          text-align: center;
          .border-radius(10px);
          .transition(all .25s ease-in-out);
          &:hover {
            //background-color: @color-quinary;
            cursor: pointer;
          }
        }
        &:checked + label {
          background-color: @color-secondary;
        }
        &.unselected + label {
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }
    &.form-radios-multiple {
      .form-item {
        margin-bottom: 24px;
      }
      input[type="radio"] {
        display: none;
        + label {
          .fontRegular;
          font-size: 1rem;
          line-height: 1.4705882353em;
          margin-bottom: 0;
          padding-left: 40px;
          position: relative;
          .transition(color .25s ease);
          &:hover {
            //color: @color-primary;
            cursor: pointer;
            &::before,
            &::after {
              //.opacity(1);
            }
          }
          &::before {
            background-color: @color-primary;
            content: '';
            display: block;
            height: 30px;
            left: 0;
            position: absolute;
            top: -2px;
            width: 30px;
            .border-radius(30px);
            .transition(opacity .25s ease);
            .opacity(0.15);
          }
          &::after {
            background-color: @color-secondary;
            content: '';
            display: block;
            height: 15px;
            left: 7.25px;
            position: absolute;
            top: 5.5px;
            width: 15px;
            .border-radius(15px);
            .transition(opacity .25s ease);
            .opacity(0);
          }
        }
        &:checked + label {
          &::before,
          &::after {
            .opacity(1);
          }
        }
        &.unselected + label {
          color: rgba(67, 41, 87, 0.5);
          &:hover {
            //color: @color-primary;
          }
        }
        .bg-primary & {
          + label {
            &:hover {
              //color: @white;
            }
            &::before {
              background-color: @white;
            }
            &::after {
              background-color: @color-primary;
            }
          }
          &.unselected + label {
            color: rgba(255, 255, 255, 0.5);
            &:hover {
              //color: @white;
            }
          }
        }
      }
    }
    // Trivia.
    .answer {
      padding: 7px 0;
      text-align: center;
    }
    .form-type-radio {
      position: relative;
      input[type="radio"].correct:checked + label {
        background-color: @color-success;
      }
      input[type="radio"].wrong:checked + label {
        background-color: @color-incorrect;
      }
    }
  }

  // Checkboxes
  .form-checkboxes {
    .form-item {
      margin-bottom: 24px;
    }
    input[type="checkbox"] {
      display: none;
      + label {
        .fontRegular;
        font-size: 1rem;
        line-height: 1.4705882353em;
        margin-bottom: 0;
        padding-left: 40px;
        position: relative;
        .transition(color .25s ease);
        &:hover {
          //color: @color-primary;
          cursor: pointer;
          &::before,
          &::after {
            //.opacity(1);
          }
        }
        &::before {
          background-color: @color-primary;
          content: '';
          display: block;
          height: 30px;
          left: 0;
          position: absolute;
          top: -2px;
          width: 30px;
          .transition(opacity .25s ease);
          .opacity(0.15);
        }
        &::after {
          background-color: transparent;
          background-image: url('../images/check-secondary.svg');
          background-size: contain;
          content: '';
          display: block;
          height: 15px;
          left: 8px;
          position: absolute;
          top: 6px;
          width: 15px;
          .transition(opacity .25s ease);
          .opacity(0);
        }
      }
      &:checked + label {
        &::before,
        &::after {
          .opacity(1);
        }
      }
      &.unselected + label {
        color: rgba(67, 41, 87, 0.5);
        &:hover {
          //color: @color-primary;
        }
      }
      .bg-primary & {
        + label {
          &:hover {
            //color: @white;
          }
          &::before {
            background-color: @white;
          }
          &::after {
            background-image: url('../images/check-primary.svg');
          }
        }
        &.unselected + label {
          color: rgba(255, 255, 255, 0.5);
          &:hover {
            //color: @white;
          }
        }
      }
    }
  }

  // Actions
  .form-actions {
    margin-top: 1em;
    input[type="submit"],
    input[type="submit"]:focus {
      .btn;
      .btn-primary;
    }
  }

  // Show/hide password.
  .form-type-password {
    position: relative;
    input {
      background-image: none !important;
    }
  }
  .shwpd {
    appearance: none;
    background: none;
    border: none;
    bottom: 0;
    height: 43px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 43px;
    i {
      display: none;
    }
    &.eye-close {
      .icon-eye-close {
        display: block;
      }
    }
    &.eye-open {
      .icon-eye-open {
        display: block;
      }
    }
  }
}

// LIBRA
.mainContent.libra {
  padding-top: 55px;
  // Textual progress
  .libra-progress {
    color: @color-secondary;
    .fontSemibold;
    font-size: 1rem;
    margin-bottom: 1.2352941176em;
    text-align: center;
  }
  // Question
  .question {
    .title {
      color: @white;
      .fontLight;
      margin-bottom: 1.59em;
      text-align: center;
      i[class^="icon-"],
      i[class*=" icon-"] {
        .fontItalic;
        font-size: 0.65em;
      }
    }
  }
  .question,
  .message {
    .media--type-image img {
      height: auto;
      max-width: 100%;
      width: auto;
    }
    a:not(.btn) {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .given-answer {
    margin-top: 1.5rem;
  }
  // Controls
  .controls {
    margin: 3.5294117647rem 0;
    .clearfix;
    .form-submit {
      .fontSemibold;
      font-size: 1rem;
      .transition(all .25s ease-in-out);
      &:disabled,
      &:disabled:hover {
        color: rgba(0, 0, 0, 0.5);
      }
      &:hover {
        color: @color-secondary;
      }
      i {
        display: inline-block;
        font-size: 1.35rem;
        vertical-align: middle;
        .translate(0, -3px);
        .firefox & {
          .translate(0, -4px);
        }
      }
    }
    .btn-prev {
      color: rgba(0, 0, 0, 0.5);
      float: left;
      i {
        margin-right: 2px;
      }
    }
    .btn-next {
      float: right;
      i {
        margin-left: 8px;
      }
    }
  }
  // Cancel
  .cancel {
    text-align: center;
    a {
      color: @white;
      display: inline-block;
      font-size: 0.9411764706rem;
      padding: 10px;
      .opacity(0.75);
      &:hover {
        .opacity(1);
        text-decoration: none;
      }
      span {
        color: @color-secondary;
      }
    }
  }
  &.libra-full {
    padding-top: 25px;
  }
}

#libra-short {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}
#libra-full {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  .question {
    .title {
      color: @color-primary;
      .fontBold;
      font-size: 1.2941176471rem;
    }
  }
  .form-radios {
    margin-top: 30px;
  }
}

// USER FORM (REGISTREREN, INLOGGEN, WACHTWOORD VERGETEN)
.user-form {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: left;
  .form-item {
    margin-bottom: 1rem;
    label {
      &.visually-hidden {
        display: none;
      }
      .no-placeholder & {
        display: block;
        text-align: left;
      }
    }
  }
  .form-radios {
    > label {
      margin-bottom: 7px;
    }
  }
  .form-checkboxes {
    margin-top: 2rem;
  }
  .form-actions {
    .btn,
    .btn:focus {
      .border-radius(10px);
      display: block;
      font-size: 1.1764705882rem;
      margin-bottom: 12px;
      width: 100%;
    }
    .cancel {
      font-size: 0.8823529412rem;
    }
  }
}

.user-form,
.approve-policy-statement {
  p {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media (min-width: @bp-small) {
  .mainContent.libra {
    &.libra-full {
      padding-top: 55px;
    }
  }
}
