//
// Icons
// --------------------------------------------------

// RESET ICON FONT
i[class^="icon-"],
i[class*=" icon-"] {
  line-height: 1em;
  &:before {
    margin: 0 -0.04em;
    width: auto;
  }
}