//
// Search
// --------------------------------------------------

.search {
  position: relative;
  .form-item,
  .form-actions {
    margin: 0;
    padding: 0;
  }
  .searchBar {
    background-color: @gray-lighter;
    border: none;
    color: @black;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0 40px 0 15px;
    width: 100%;
    -webkit-appearance: none;
    .border-radius(30px);
    .box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075));
    .transition(all .15s ease);
    &:focus {
      color: @black;
      outline: 0;
    }
  }
  .searchSubmit {
    background-color: transparent;
    border: none;
    color: @color-primary;
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 8px;
    top: 2px;
    -webkit-appearance: none;
    &:focus {
      outline: 0;
    }
    i {
      color: inherit;
      font-size: 25px;
    }
  }
}