//
// Drupal
// --------------------------------------------------

// TABSMESSAGES
.tabsMessages {
  margin: 20px 0;
  max-width: 100%;
  overflow: hidden;
}

// MESSAGES
.messages {
  margin-left: 8px;
}
// D8 messages copied CSS for Betabase
.betabase {
  .messages {
    background: no-repeat 10px 17px;  /* LTR */
    border: 1px solid;
    border-width: 1px 1px 1px 0;  /* LTR */
    border-radius: 2px;
    padding: 15px 20px 15px 35px; /* LTR */
    word-wrap: break-word;
    overflow-wrap: break-word;
    [dir="rtl"] & {
      border-width: 1px 0 1px 1px;
      background-position: right 10px top 17px;
      padding-left: 20px;
      padding-right: 35px;
      text-align: right;
    }
    + .messages {
      margin-top: 1.538em;
    }
    &:last-child {
      margin-bottom: 1.538em;
    }
  }
  .messages__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .messages__item + .messages__item {
    margin-top: 0.769em;
  }
  .messages--status {
    color: #325e1c;
    background-color: #f3faef;
    border-color: #c9e1bd #c9e1bd #c9e1bd transparent;  /* LTR */
    background-image: url(../images/check.svg);
    box-shadow: -8px 0 0 #77b259; /* LTR */
  }
  [dir="rtl"] .messages--status {
    border-color: #c9e1bd transparent #c9e1bd #c9e1bd;
    box-shadow: 8px 0 0 #77b259;
    margin-left: 0;
  }
  .messages--warning {
    background-color: #fdf8ed;
    background-image: url(../images/warning.svg);
    border-color: #f4daa6 #f4daa6 #f4daa6 transparent;  /* LTR */
    color: #734c00;
    box-shadow: -8px 0 0 #e09600; /* LTR */
  }
  [dir="rtl"] .messages--warning {
    border-color: #f4daa6 transparent #f4daa6 #f4daa6;
    box-shadow: 8px 0 0 #e09600;
  }
  .messages--error {
    background-color: #fcf4f2;
    color: #a51b00;
    background-image: url(../images/error.svg);
    border-color: #f9c9bf #f9c9bf #f9c9bf transparent;  /* LTR */
    box-shadow: -8px 0 0 #e62600; /* LTR */
  }
  [dir="rtl"] .messages--error {
    border-color: #f9c9bf transparent #f9c9bf #f9c9bf;
    box-shadow: 8px 0 0 #e62600;
  }
  .messages--error p.error {
    color: #a51b00;
  }
}

// TABS
ul.tabs {
  border: none;
  margin: 0 0 1em;
  padding: 0;
  .clearfix;
  li,
  li.active {
    display: block;
    margin: 0 0 10px 0;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      background: url('../images/drupal7-buttons.png');
      border: 1px solid;
      border-color: #e4e4e4 #d2d2d2 #b4b4b4;
      color: @gray-dark;
      cursor: pointer;
      display: block;
      font-family: "Lucida Grande", Verdana, sans-serif;
      font-size: 13px;
      margin: 0;
      padding: 3px 11px;
      text-align: center;
      text-decoration: none;
      .border-radius(20px);
      .transition(all .1s ease);
      &:hover {
        border-color: #b4b4b4;
        background-position: left -40px;
        color: #222222;
        .box-shadow(1px 1px 2px rgba(0, 0, 0, 0.2) inset);
      }
    }
  }
}

@media (min-width: 400px) {
  // TABS
  ul.tabs {
    margin-bottom: 0;
    li,
    li.active {
      float: left;
      margin-right: 10px;
      a {
        padding: 1px 15px;
      }
    }
  }
}

@media (min-width: @bp-medium) {
  // TABSMESSAGES
  .tabsMessages {
    margin-bottom: 45px;
  }
}

.element-invisible {
  display: none;
}

html.js .js-hide {
  display: none;
}