//
// Animation
// --------------------------------------------------

@keyframes noot-top {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  9% {
    transform: translate(1px, -2px) rotate(0.5deg);
  }
  20% {
    transform: translate(0, 2px) rotate(-1deg);
  }
  49% {
    transform: translate(-1px, 4px) rotate(-3deg);
  }
  60% {
    transform: translate(0, 2px) rotate(-2deg);
  }
  100% {
    transform: translate(-2px, 10px) rotate(-7.25deg);
  }
}

@keyframes noot-bottom {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  9% {
    transform: translate(-1px, -2px) rotate(-0.4deg);
  }
  20% {
    transform: translate(0, 0) rotate(0);
  }
  49% {
    transform: translate(-1px, 0) rotate(3deg);
  }
  60% {
    transform: translate(0, -3px) rotate(2deg);
  }
  100% {
    transform: translate(3px, 13px) rotate(1.11deg);
  }
}


@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float-shadow {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}

// CUI - LOADING BUTTON
@-webkit-keyframes wave {
  0%, 60%, 100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes wave {
  0%, 60%, 100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

// CUI - FLY-IN MESSAGES
@keyframes fly-in {
  0% {
    -webkit-transform: scale(0.85) translateY(10%);
    -ms-transform: scale(0.85) translateY(10%);
    transform: scale(0.85) translateY(10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

div.particle-button {
  z-index: 1;
  color: var(--buttonFontColor);
  padding: 0em;
  font-size: var(--buttonFontSize);
  position: absolute;
  width: 90px;
  height: 90px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1, 1);

  background-color: unset;
  background: var(--buttonColor0);
  pointer-events: all;
  cursor: pointer;
  border-radius: 50%;
  border-width: 0px;
  box-sizing: border-box;
  transition: box-shadow ease-out 0.3s, transform 0.1s ease-in;
  box-shadow: 0px 0px 0px 5px var(--buttonColor2),
  0px 0px 0px 9px var(--buttonColor1),
  inset 0px 0px 0px 0px var(--buttonColor2);
}
div.particle-button:hover:not(:active) {
  transition: 0.1s ease-in-out all;
  box-shadow: 0px 0px 0px 8px var(--buttonColor2),
  0px 0px 0px 14px var(--buttonColor1),
  inset 0px 0px 0px 0px var(--buttonColor2);

  transform: translate(-50%, -50%) scale(0.95, 0.95);
}
.particles {
  z-index: -1;
  background: none !important;
  pointer-events: none !important;
  cursor: pointer;
  box-shadow: none;
}
.particles::after {
  position: absolute;
  content: "";
  left: -105px;
  top: -105px;
  min-width: 300px;
  min-height: 300px;
  pointer-events: none;
  transition: background-size ease-in-out 0.5s,
  background-position ease-in-out 0.5s;
  background-repeat: no-repeat;
  overflow: hidden;
}

div.particle-button:active {
  transition: box-shadow ease-out 0.1s, background-size ease-in-out 0.1s,
  background-position ease-in-out 0.1s;
  box-shadow: 0px 0px 0px 0px var(--buttonColor2),
  0px 0px 0px 5px var(--buttonColor1),
  inset 0px 0px 0px 5px var(--buttonColor2);

  transform: translate(-50%, -50%) scale(0.9, 0.9);
}
.particle-button:active ~ .particles::after {
  animation: none;
  background-size: 0;
}
.particle-button.animate::after {
  animation: theButtonAnimation linear 1.45s forwards;
  background-image:
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%),
          radial-gradient(circle, rgba(64, 133, 30, 1) 45%, transparent 45%);
}
@keyframes theButtonAnimation {
  0% {

    background-size: 63px 63px,41px 41px,23px 23px,50px 50px,57px 57px,52px 52px,42px 42px,39px 39px,68px 68px,67px 67px,16px 16px,32px 32px,47px 47px,43px 43px,20px 20px,24px 24px,47px 47px,17px 17px,68px 68px,37px 37px,33px 33px,40px 40px,29px 29px,59px 59px,46px 46px,39px 39px,30px 30px,46px 46px,23px 23px,48px 48px,46px 46px,68px 68px,41px 41px,27px 27px,60px 60px,69px 69px,30px 30px,39px 39px,67px 67px,17px 17px,64px 64px,42px 42px,55px 55px;
    background-position: 512px 474px,522px 492px,528px 507px,512px 499px,504px 501px,502px 508px,502px 517px,497px 521px,476px 509px,471px 510px,490px 536px,475px 527px,461px 518px,457px 518px,463px 526px,456px 520px,440px 503px,452px 513px,423px 481px,437px 491px,438px 486px,434px 476px,441px 475px,428px 453px,437px 454px,444px 452px,453px 452px,450px 439px,467px 448px,461px 433px,468px 432px,463px 420px,483px 434px,497px 442px,487px 428px,488px 426px,513px 449px,513px 450px,503px 441px,532px 472px,511px 454px,523px 472px,517px 472px;
  }
  3.3% {

    background-size: 51px 51px,31px 31px,19px 19px,41px 41px,46px 46px,44px 44px,34px 34px,32px 32px,59px 59px,58px 58px,13px 13px,27px 27px,40px 40px,36px 36px,16px 16px,20px 20px,38px 38px,14px 14px,55px 55px,31px 31px,26px 26px,32px 32px,25px 25px,50px 50px,38px 38px,33px 33px,25px 25px,38px 38px,19px 19px,41px 41px,38px 38px,56px 56px,35px 35px,23px 23px,51px 51px,53px 53px,24px 24px,30px 30px,55px 55px,14px 14px,54px 54px,33px 33px,47px 47px;
    background-position: 608px 510px,611px 519px,606px 544px,553px 542px,572px 548px,560px 590px,548px 583px,518px 589px,498px 570px,478px 602px,510px 632px,477px 605px,419px 614px,414px 605px,431px 583px,394px 598px,374px 562px,384px 586px,354px 531px,363px 510px,395px 490px,354px 483px,381px 462px,361px 431px,359px 419px,369px 403px,427px 409px,427px 380px,417px 375px,436px 371px,462px 366px,470px 367px,500px 378px,505px 380px,506px 379px,536px 361px,540px 402px,554px 399px,575px 409px,618px 436px,580px 452px,577px 478px,575px 486px;
  }
  6.6% {

    background-size: 41px 41px,24px 24px,15px 15px,34px 34px,37px 37px,38px 38px,27px 27px,27px 27px,50px 50px,49px 49px,10px 10px,22px 22px,33px 33px,31px 31px,13px 13px,17px 17px,30px 30px,12px 12px,44px 44px,26px 26px,20px 20px,25px 25px,21px 21px,42px 42px,31px 31px,28px 28px,21px 21px,31px 31px,16px 16px,34px 34px,30px 30px,47px 47px,29px 29px,20px 20px,43px 43px,42px 42px,20px 20px,24px 24px,46px 46px,12px 12px,46px 46px,26px 26px,40px 40px;
    background-position: 658px 532px,657px 536px,646px 567px,577px 571px,609px 579px,591px 636px,574px 623px,530px 632px,511px 611px,484px 654px,520px 684px,478px 651px,400px 667px,394px 653px,414px 619px,364px 641px,341px 596px,351px 625px,321px 561px,325px 523px,372px 495px,315px 490px,350px 457px,329px 421px,321px 403px,331px 380px,413px 384px,416px 349px,392px 339px,425px 339px,461px 331px,475px 337px,510px 346px,510px 345px,519px 351px,563px 329px,556px 375px,576px 372px,613px 395px,662px 419px,619px 452px,608px 484px,608px 496px;
  }
  10% {

    background-size: 33px 33px,19px 19px,12px 12px,28px 28px,30px 30px,32px 32px,21px 21px,22px 22px,43px 43px,42px 42px,8px 8px,18px 18px,28px 28px,26px 26px,11px 11px,14px 14px,24px 24px,10px 10px,36px 36px,21px 21px,16px 16px,20px 20px,18px 18px,35px 35px,25px 25px,24px 24px,18px 18px,25px 25px,13px 13px,29px 29px,25px 25px,39px 39px,24px 24px,17px 17px,37px 37px,32px 32px,16px 16px,18px 18px,38px 38px,10px 10px,39px 39px,20px 20px,33px 33px;
    background-position: 685px 547px,683px 548px,669px 581px,592px 592px,630px 598px,608px 662px,588px 647px,537px 658px,520px 638px,488px 685px,526px 713px,479px 678px,391px 696px,384px 680px,405px 642px,349px 665px,325px 617px,334px 647px,305px 580px,306px 533px,359px 500px,294px 496px,333px 456px,312px 419px,302px 397px,313px 370px,405px 371px,410px 334px,379px 322px,419px 323px,461px 314px,479px 321px,517px 330px,513px 327px,527px 336px,579px 316px,567px 360px,590px 359px,636px 390px,685px 412px,642px 455px,628px 489px,628px 505px;
  }
  13.3% {

    background-size: 27px 27px,15px 15px,10px 10px,23px 23px,24px 24px,28px 28px,17px 17px,19px 19px,37px 37px,36px 36px,6px 6px,15px 15px,23px 23px,22px 22px,9px 9px,11px 11px,19px 19px,8px 8px,29px 29px,18px 18px,12px 12px,15px 15px,16px 16px,30px 30px,21px 21px,20px 20px,15px 15px,20px 20px,11px 11px,24px 24px,20px 20px,32px 32px,20px 20px,14px 14px,31px 31px,25px 25px,12px 12px,14px 14px,31px 31px,9px 9px,33px 33px,16px 16px,28px 28px;
    background-position: 701px 557px,698px 557px,681px 591px,603px 607px,643px 612px,618px 679px,598px 663px,542px 676px,526px 657px,491px 704px,529px 730px,480px 696px,387px 714px,380px 697px,399px 657px,341px 680px,317px 630px,325px 661px,297px 593px,295px 540px,349px 506px,283px 501px,322px 457px,302px 420px,293px 396px,303px 367px,399px 364px,407px 327px,373px 315px,417px 316px,461px 306px,482px 314px,521px 321px,516px 318px,533px 328px,589px 310px,574px 353px,600px 353px,651px 389px,699px 411px,658px 459px,642px 494px,643px 513px;
  }
  16.6% {

    background-size: 22px 22px,11px 11px,8px 8px,19px 19px,19px 19px,24px 24px,13px 13px,15px 15px,32px 32px,31px 31px,4px 4px,12px 12px,19px 19px,19px 19px,7px 7px,9px 9px,15px 15px,7px 7px,23px 23px,15px 15px,10px 10px,12px 12px,13px 13px,25px 25px,17px 17px,17px 17px,12px 12px,16px 16px,9px 9px,21px 21px,16px 16px,26px 26px,17px 17px,12px 12px,26px 26px,19px 19px,10px 10px,11px 11px,26px 26px,7px 7px,28px 28px,12px 12px,24px 24px;
    background-position: 712px 566px,707px 564px,690px 599px,612px 619px,652px 623px,625px 691px,604px 675px,546px 689px,531px 672px,494px 718px,531px 742px,481px 709px,385px 727px,378px 709px,396px 669px,337px 690px,313px 641px,320px 671px,293px 604px,288px 547px,341px 511px,277px 506px,314px 459px,297px 422px,287px 398px,298px 367px,395px 360px,406px 324px,369px 312px,415px 313px,462px 302px,485px 311px,525px 317px,518px 313px,538px 324px,595px 309px,580px 348px,606px 350px,661px 391px,707px 411px,669px 464px,653px 499px,654px 520px;
  }
  20% {

    background-size: 18px 18px,9px 9px,7px 7px,16px 16px,16px 16px,20px 20px,11px 11px,13px 13px,27px 27px,27px 27px,3px 3px,10px 10px,16px 16px,16px 16px,6px 6px,8px 8px,12px 12px,6px 6px,18px 18px,12px 12px,7px 7px,9px 9px,11px 11px,21px 21px,14px 14px,14px 14px,10px 10px,13px 13px,8px 8px,17px 17px,13px 13px,22px 22px,14px 14px,10px 10px,22px 22px,15px 15px,8px 8px,8px 8px,21px 21px,6px 6px,23px 23px,10px 10px,20px 20px;
    background-position: 719px 573px,714px 570px,696px 606px,618px 630px,659px 631px,629px 700px,608px 685px,549px 699px,535px 683px,497px 729px,532px 751px,482px 719px,385px 736px,377px 719px,394px 678px,334px 698px,310px 649px,317px 678px,290px 612px,284px 553px,334px 516px,272px 511px,309px 462px,293px 425px,284px 400px,295px 368px,392px 358px,404px 323px,367px 311px,415px 311px,463px 300px,487px 309px,527px 314px,520px 310px,543px 322px,601px 309px,584px 346px,612px 349px,668px 393px,712px 413px,678px 468px,662px 504px,663px 526px;
  }
  23.3% {

    background-size: 14px 14px,7px 7px,5px 5px,13px 13px,12px 12px,17px 17px,8px 8px,11px 11px,23px 23px,23px 23px,3px 3px,8px 8px,14px 14px,13px 13px,5px 5px,6px 6px,10px 10px,5px 5px,15px 15px,10px 10px,6px 6px,7px 7px,10px 10px,18px 18px,11px 11px,12px 12px,8px 8px,11px 11px,7px 7px,15px 15px,10px 10px,18px 18px,12px 12px,9px 9px,19px 19px,12px 12px,6px 6px,6px 6px,17px 17px,5px 5px,20px 20px,7px 7px,17px 17px;
    background-position: 724px 579px,719px 576px,700px 612px,623px 639px,664px 639px,633px 708px,612px 693px,551px 708px,538px 694px,499px 738px,533px 759px,483px 727px,385px 744px,376px 726px,392px 686px,333px 705px,308px 656px,315px 685px,288px 619px,281px 559px,328px 521px,269px 516px,304px 465px,290px 429px,281px 403px,292px 371px,389px 356px,403px 323px,365px 312px,414px 311px,463px 299px,489px 308px,530px 313px,522px 309px,546px 321px,605px 310px,588px 344px,616px 349px,674px 396px,717px 415px,685px 473px,670px 509px,671px 533px;
  }
  26.6% {

    background-size: 11px 11px,5px 5px,4px 4px,10px 10px,10px 10px,15px 15px,7px 7px,9px 9px,20px 20px,20px 20px,2px 2px,7px 7px,11px 11px,11px 11px,4px 4px,5px 5px,8px 8px,4px 4px,12px 12px,8px 8px,4px 4px,6px 6px,8px 8px,15px 15px,9px 9px,10px 10px,7px 7px,9px 9px,6px 6px,12px 12px,8px 8px,15px 15px,10px 10px,8px 8px,16px 16px,9px 9px,5px 5px,5px 5px,14px 14px,4px 4px,17px 17px,6px 6px,14px 14px;
    background-position: 729px 585px,723px 581px,704px 617px,628px 647px,668px 646px,636px 715px,614px 701px,553px 716px,540px 703px,501px 746px,534px 765px,484px 735px,385px 751px,376px 734px,390px 694px,331px 711px,307px 662px,313px 690px,287px 626px,278px 564px,322px 526px,266px 520px,300px 468px,287px 432px,279px 406px,290px 373px,386px 355px,402px 322px,363px 312px,413px 311px,463px 299px,491px 307px,532px 312px,523px 307px,550px 320px,608px 311px,592px 343px,620px 349px,680px 399px,720px 417px,691px 477px,677px 513px,678px 538px;
  }
  30% {

    background-size: 9px 9px,4px 4px,3px 3px,8px 8px,8px 8px,12px 12px,5px 5px,7px 7px,17px 17px,17px 17px,1px 1px,6px 6px,9px 9px,10px 10px,3px 3px,4px 4px,6px 6px,3px 3px,9px 9px,7px 7px,3px 3px,4px 4px,7px 7px,12px 12px,7px 7px,9px 9px,6px 6px,7px 7px,5px 5px,10px 10px,7px 7px,12px 12px,8px 8px,6px 6px,13px 13px,7px 7px,4px 4px,4px 4px,12px 12px,4px 4px,14px 14px,4px 4px,12px 12px;
    background-position: 733px 590px,727px 586px,707px 622px,632px 654px,672px 653px,639px 722px,617px 708px,555px 724px,543px 712px,502px 754px,535px 772px,484px 742px,385px 758px,376px 740px,389px 701px,330px 717px,305px 668px,311px 696px,285px 632px,275px 569px,317px 530px,263px 524px,296px 471px,285px 436px,276px 409px,288px 375px,383px 355px,400px 323px,361px 313px,412px 311px,463px 299px,492px 307px,534px 311px,524px 306px,552px 320px,611px 312px,595px 343px,624px 349px,685px 401px,724px 420px,697px 481px,683px 518px,684px 544px;
  }
  33.3% {

    background-size: 7px 7px,3px 3px,3px 3px,7px 7px,6px 6px,11px 11px,4px 4px,6px 6px,14px 14px,14px 14px,1px 1px,5px 5px,8px 8px,8px 8px,2px 2px,3px 3px,5px 5px,3px 3px,8px 8px,6px 6px,3px 3px,3px 3px,6px 6px,10px 10px,6px 6px,7px 7px,5px 5px,5px 5px,4px 4px,9px 9px,5px 5px,10px 10px,7px 7px,5px 5px,11px 11px,5px 5px,3px 3px,3px 3px,10px 10px,3px 3px,12px 12px,3px 3px,10px 10px;
    background-position: 736px 596px,730px 591px,710px 628px,635px 662px,675px 659px,641px 728px,619px 714px,556px 731px,545px 720px,504px 761px,535px 777px,484px 749px,385px 764px,375px 746px,387px 707px,329px 723px,304px 674px,310px 701px,284px 638px,273px 574px,312px 535px,260px 529px,292px 474px,282px 439px,274px 411px,286px 378px,381px 354px,399px 323px,360px 314px,411px 312px,463px 298px,493px 306px,535px 310px,525px 306px,555px 319px,614px 313px,599px 342px,627px 349px,689px 404px,727px 422px,702px 485px,688px 522px,689px 549px;
  }
  36.6% {

    background-size: 6px 6px,2px 2px,2px 2px,6px 6px,5px 5px,9px 9px,3px 3px,5px 5px,12px 12px,12px 12px,1px 1px,4px 4px,6px 6px,7px 7px,2px 2px,3px 3px,4px 4px,2px 2px,6px 6px,5px 5px,2px 2px,3px 3px,5px 5px,9px 9px,5px 5px,6px 6px,4px 4px,4px 4px,3px 3px,7px 7px,4px 4px,8px 8px,5px 5px,5px 5px,9px 9px,4px 4px,2px 2px,2px 2px,8px 8px,3px 3px,10px 10px,3px 3px,8px 8px;
    background-position: 739px 601px,733px 596px,713px 633px,638px 668px,678px 665px,643px 735px,621px 720px,558px 738px,547px 727px,505px 768px,536px 783px,485px 756px,384px 770px,375px 752px,386px 714px,328px 728px,302px 680px,309px 707px,282px 643px,270px 579px,308px 539px,258px 533px,288px 478px,280px 442px,272px 414px,284px 380px,378px 354px,398px 323px,358px 315px,410px 312px,463px 298px,494px 305px,537px 309px,526px 305px,558px 319px,616px 314px,602px 341px,630px 350px,694px 407px,730px 425px,707px 489px,694px 526px,694px 555px;
  }
  40% {

    background-size: 5px 5px,2px 2px,2px 2px,5px 5px,4px 4px,8px 8px,2px 2px,4px 4px,10px 10px,11px 11px,0px 0px,3px 3px,5px 5px,6px 6px,1px 1px,2px 2px,3px 3px,2px 2px,5px 5px,4px 4px,1px 1px,2px 2px,4px 4px,7px 7px,4px 4px,5px 5px,3px 3px,3px 3px,3px 3px,6px 6px,3px 3px,7px 7px,4px 4px,4px 4px,8px 8px,3px 3px,2px 2px,2px 2px,6px 6px,2px 2px,8px 8px,2px 2px,7px 7px;
    background-position: 742px 606px,736px 600px,715px 638px,641px 675px,680px 671px,645px 740px,623px 726px,559px 744px,548px 734px,506px 774px,536px 789px,485px 762px,384px 776px,375px 758px,385px 720px,327px 734px,301px 685px,307px 712px,281px 649px,268px 583px,303px 544px,255px 537px,285px 481px,277px 445px,270px 417px,282px 383px,375px 354px,396px 323px,357px 317px,409px 312px,463px 298px,495px 305px,538px 309px,527px 304px,560px 318px,619px 315px,604px 341px,633px 350px,698px 409px,733px 428px,712px 493px,698px 530px,699px 560px;
  }
  43.3% {

    background-size: 4px 4px,1px 1px,1px 1px,4px 4px,3px 3px,6px 6px,2px 2px,3px 3px,9px 9px,9px 9px,0px 0px,2px 2px,4px 4px,5px 5px,1px 1px,2px 2px,2px 2px,1px 1px,4px 4px,3px 3px,1px 1px,1px 1px,4px 4px,6px 6px,3px 3px,4px 4px,3px 3px,3px 3px,2px 2px,5px 5px,3px 3px,5px 5px,4px 4px,3px 3px,7px 7px,2px 2px,1px 1px,1px 1px,5px 5px,2px 2px,7px 7px,1px 1px,6px 6px;
    background-position: 745px 610px,738px 605px,718px 643px,644px 681px,683px 676px,646px 746px,624px 732px,560px 751px,550px 741px,507px 781px,536px 794px,485px 768px,384px 781px,374px 764px,384px 726px,326px 739px,300px 690px,306px 717px,279px 654px,265px 588px,299px 548px,253px 541px,281px 484px,274px 448px,267px 420px,280px 385px,372px 354px,394px 323px,355px 318px,408px 312px,463px 298px,495px 304px,540px 308px,528px 303px,562px 318px,621px 316px,607px 340px,636px 350px,701px 412px,736px 430px,716px 497px,703px 535px,703px 565px;
  }
  46.6% {

    background-size: 3px 3px,1px 1px,1px 1px,3px 3px,2px 2px,5px 5px,1px 1px,3px 3px,8px 8px,8px 8px,0px 0px,2px 2px,4px 4px,4px 4px,1px 1px,1px 1px,2px 2px,1px 1px,3px 3px,2px 2px,1px 1px,1px 1px,3px 3px,5px 5px,2px 2px,4px 4px,2px 2px,2px 2px,2px 2px,4px 4px,2px 2px,4px 4px,3px 3px,3px 3px,6px 6px,2px 2px,1px 1px,1px 1px,4px 4px,1px 1px,6px 6px,1px 1px,5px 5px;
    background-position: 747px 615px,741px 609px,720px 647px,646px 688px,685px 682px,648px 752px,625px 738px,561px 757px,551px 748px,507px 787px,537px 800px,485px 774px,384px 787px,374px 770px,383px 732px,325px 744px,298px 696px,305px 722px,278px 659px,263px 593px,295px 553px,250px 545px,278px 488px,272px 451px,265px 422px,278px 387px,369px 354px,392px 323px,353px 319px,407px 312px,462px 297px,496px 303px,541px 307px,529px 303px,565px 317px,623px 316px,610px 340px,639px 351px,705px 414px,739px 433px,720px 501px,707px 539px,707px 570px;
  }
  50% {

    background-size: 2px 2px,0px 0px,1px 1px,2px 2px,2px 2px,5px 5px,1px 1px,2px 2px,6px 6px,7px 7px,0px 0px,2px 2px,3px 3px,3px 3px,1px 1px,1px 1px,1px 1px,1px 1px,2px 2px,2px 2px,0px 0px,1px 1px,3px 3px,4px 4px,2px 2px,3px 3px,2px 2px,2px 2px,1px 1px,3px 3px,2px 2px,4px 4px,2px 2px,2px 2px,5px 5px,1px 1px,1px 1px,0px 0px,3px 3px,1px 1px,5px 5px,1px 1px,4px 4px;
    background-position: 750px 620px,743px 614px,722px 652px,648px 694px,687px 687px,649px 757px,627px 744px,562px 763px,552px 754px,508px 793px,537px 805px,485px 780px,383px 792px,373px 775px,382px 737px,324px 749px,297px 701px,304px 727px,276px 664px,261px 597px,292px 557px,248px 549px,275px 491px,269px 455px,263px 425px,276px 390px,367px 354px,391px 323px,351px 320px,406px 312px,462px 297px,496px 303px,542px 307px,530px 302px,567px 317px,625px 317px,613px 340px,642px 351px,709px 417px,742px 436px,724px 505px,711px 543px,711px 574px;
  }
  53.3% {

    background-size: 2px 2px,0px 0px,0px 0px,2px 2px,1px 1px,4px 4px,1px 1px,2px 2px,5px 5px,6px 6px,0px 0px,1px 1px,2px 2px,3px 3px,0px 0px,1px 1px,1px 1px,1px 1px,2px 2px,2px 2px,0px 0px,0px 0px,2px 2px,3px 3px,2px 2px,3px 3px,1px 1px,1px 1px,1px 1px,3px 3px,1px 1px,3px 3px,2px 2px,2px 2px,4px 4px,1px 1px,0px 0px,0px 0px,3px 3px,1px 1px,4px 4px,0px 0px,3px 3px;
    background-position: 752px 624px,745px 618px,724px 657px,650px 699px,689px 693px,651px 762px,628px 749px,562px 769px,553px 761px,509px 799px,537px 810px,485px 786px,383px 797px,373px 780px,381px 743px,323px 754px,295px 706px,303px 732px,275px 669px,259px 602px,288px 562px,245px 553px,271px 494px,266px 458px,260px 428px,274px 392px,364px 354px,389px 323px,350px 321px,404px 312px,461px 296px,497px 302px,543px 306px,531px 301px,569px 316px,628px 318px,615px 340px,645px 352px,712px 419px,744px 439px,728px 509px,715px 547px,714px 579px;
  }
  56.6% {

    background-size: 1px 1px,0px 0px,0px 0px,1px 1px,1px 1px,3px 3px,0px 0px,1px 1px,5px 5px,5px 5px,0px 0px,1px 1px,2px 2px,2px 2px,0px 0px,1px 1px,1px 1px,1px 1px,1px 1px,1px 1px,0px 0px,0px 0px,2px 2px,3px 3px,1px 1px,2px 2px,1px 1px,1px 1px,1px 1px,2px 2px,1px 1px,2px 2px,2px 2px,2px 2px,3px 3px,0px 0px,0px 0px,0px 0px,2px 2px,1px 1px,3px 3px,0px 0px,3px 3px;
    background-position: 754px 629px,747px 623px,726px 662px,652px 705px,691px 698px,652px 768px,629px 754px,563px 774px,554px 767px,509px 804px,538px 815px,485px 791px,383px 802px,372px 785px,380px 748px,322px 759px,294px 710px,301px 737px,273px 674px,257px 606px,285px 566px,243px 557px,268px 498px,263px 461px,258px 430px,271px 394px,361px 355px,387px 324px,348px 322px,403px 313px,461px 296px,497px 301px,545px 305px,532px 301px,571px 316px,630px 318px,618px 340px,648px 352px,715px 421px,747px 441px,732px 513px,718px 551px,717px 584px;
  }
  60% {

    background-size: 1px 1px,0px 0px,0px 0px,1px 1px,1px 1px,3px 3px,0px 0px,1px 1px,4px 4px,4px 4px,0px 0px,1px 1px,2px 2px,2px 2px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,1px 1px,2px 2px,1px 1px,2px 2px,1px 1px,1px 1px,1px 1px,2px 2px,1px 1px,2px 2px,1px 1px,1px 1px,3px 3px,0px 0px,0px 0px,0px 0px,2px 2px,1px 1px,3px 3px,0px 0px,2px 2px;
    background-position: 756px 633px,749px 627px,728px 666px,654px 711px,693px 703px,653px 773px,630px 760px,564px 780px,555px 773px,510px 810px,538px 820px,485px 797px,382px 807px,372px 791px,379px 754px,322px 764px,293px 715px,300px 741px,272px 679px,254px 611px,282px 570px,241px 560px,265px 501px,261px 464px,255px 433px,269px 397px,358px 355px,385px 324px,346px 323px,401px 313px,460px 296px,497px 300px,546px 305px,533px 300px,572px 315px,632px 319px,621px 340px,651px 353px,719px 424px,750px 444px,735px 517px,722px 555px,721px 589px;
  }
  63.3% {

    background-size: 1px 1px,0px 0px,0px 0px,1px 1px,0px 0px,2px 2px,0px 0px,1px 1px,3px 3px,3px 3px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,1px 1px,2px 2px,1px 1px,1px 1px,1px 1px,0px 0px,0px 0px,2px 2px,0px 0px,1px 1px,1px 1px,1px 1px,2px 2px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,2px 2px,0px 0px,2px 2px;
    background-position: 758px 637px,751px 631px,730px 671px,656px 716px,694px 708px,654px 778px,631px 765px,564px 785px,556px 778px,510px 815px,538px 825px,485px 802px,382px 812px,371px 796px,378px 759px,321px 768px,292px 720px,299px 746px,270px 683px,252px 615px,279px 575px,238px 564px,262px 505px,258px 467px,253px 436px,267px 399px,355px 355px,383px 324px,344px 324px,400px 313px,460px 295px,497px 300px,547px 304px,534px 299px,574px 315px,634px 320px,623px 340px,654px 354px,722px 426px,752px 447px,738px 520px,725px 560px,724px 593px;
  }
  66.6% {

    background-size: 0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,2px 2px,0px 0px,1px 1px,3px 3px,3px 3px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px,1px 1px,1px 1px,2px 2px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,2px 2px,0px 0px,1px 1px;
    background-position: 760px 642px,753px 636px,731px 676px,657px 722px,696px 713px,655px 783px,632px 770px,565px 791px,556px 784px,510px 820px,538px 830px,485px 807px,381px 817px,371px 801px,377px 765px,320px 773px,290px 725px,298px 751px,269px 688px,250px 619px,276px 579px,236px 568px,259px 508px,255px 470px,250px 439px,265px 402px,353px 356px,381px 324px,342px 325px,398px 313px,459px 295px,497px 299px,548px 303px,534px 299px,576px 314px,636px 321px,626px 340px,656px 355px,725px 429px,755px 450px,741px 524px,728px 564px,726px 598px;
  }
  70% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,2px 2px,0px 0px,0px 0px,2px 2px,2px 2px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px,1px 1px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,2px 2px,0px 0px,1px 1px;
    background-position: 762px 646px,755px 640px,733px 680px,659px 727px,697px 718px,656px 788px,633px 775px,565px 796px,557px 790px,511px 826px,538px 835px,485px 813px,381px 822px,370px 806px,376px 770px,319px 778px,289px 730px,297px 755px,267px 693px,248px 624px,273px 583px,234px 572px,256px 512px,253px 473px,248px 441px,262px 404px,350px 357px,379px 324px,340px 326px,396px 313px,458px 294px,498px 298px,549px 303px,535px 298px,578px 314px,638px 321px,628px 340px,659px 355px,728px 431px,757px 453px,744px 528px,731px 568px,729px 603px;
  }
  73.3% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,2px 2px,2px 2px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px,0px 0px,1px 1px;
    background-position: 763px 650px,757px 644px,735px 685px,660px 732px,699px 723px,657px 793px,634px 780px,566px 801px,558px 795px,511px 831px,539px 840px,485px 818px,381px 827px,370px 810px,375px 775px,318px 783px,288px 734px,296px 760px,266px 697px,247px 628px,270px 588px,232px 576px,254px 515px,250px 476px,246px 444px,260px 406px,347px 357px,377px 325px,338px 327px,395px 313px,458px 294px,498px 297px,550px 302px,536px 298px,580px 314px,640px 322px,631px 340px,662px 356px,731px 434px,760px 456px,747px 532px,734px 572px,732px 607px;
  }
  76.6% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,2px 2px,2px 2px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px;
    background-position: 765px 655px,759px 649px,736px 689px,661px 737px,700px 727px,658px 797px,635px 785px,566px 806px,558px 800px,511px 836px,539px 845px,485px 823px,380px 832px,369px 815px,375px 780px,317px 787px,287px 739px,295px 765px,265px 702px,245px 632px,267px 592px,230px 580px,251px 519px,247px 479px,243px 447px,258px 409px,344px 358px,375px 325px,336px 328px,393px 313px,457px 294px,498px 297px,551px 302px,537px 297px,581px 313px,642px 323px,633px 340px,664px 357px,734px 437px,762px 459px,750px 535px,737px 576px,734px 612px;
  }
  80% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,1px 1px;
    background-position: 767px 659px,760px 653px,738px 694px,662px 742px,701px 732px,659px 802px,635px 790px,567px 811px,559px 806px,511px 841px,539px 849px,485px 828px,380px 836px,369px 820px,374px 785px,317px 792px,286px 743px,294px 769px,263px 706px,243px 637px,265px 596px,228px 584px,248px 522px,245px 483px,241px 450px,255px 411px,342px 359px,373px 325px,334px 330px,391px 314px,456px 293px,498px 296px,552px 301px,538px 297px,583px 313px,644px 324px,636px 341px,667px 358px,736px 439px,764px 462px,753px 539px,739px 580px,737px 616px;
  }
  83.3% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,0px 0px,0px 0px;
    background-position: 768px 663px,762px 657px,739px 698px,664px 747px,702px 737px,660px 807px,636px 795px,567px 817px,559px 811px,511px 846px,539px 854px,485px 833px,380px 841px,368px 825px,373px 790px,316px 796px,285px 748px,294px 774px,262px 711px,241px 641px,262px 601px,226px 588px,246px 526px,242px 486px,239px 453px,253px 414px,339px 359px,371px 326px,332px 331px,389px 314px,455px 293px,498px 295px,553px 301px,538px 296px,585px 313px,646px 325px,638px 341px,670px 359px,739px 442px,767px 465px,755px 543px,742px 585px,739px 621px;
  }
  86.6% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-position: 770px 667px,764px 661px,740px 703px,665px 752px,704px 741px,660px 811px,637px 800px,568px 821px,560px 816px,512px 851px,539px 859px,485px 838px,379px 845px,368px 829px,373px 795px,315px 801px,284px 753px,293px 778px,261px 715px,239px 645px,260px 605px,224px 592px,243px 530px,240px 489px,236px 456px,251px 416px,336px 360px,369px 326px,330px 332px,388px 314px,455px 293px,498px 295px,554px 300px,539px 296px,587px 312px,648px 325px,641px 342px,672px 360px,742px 445px,769px 468px,758px 547px,744px 589px,741px 625px;
  }
  90% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,1px 1px,1px 1px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-position: 771px 672px,765px 666px,742px 707px,666px 757px,705px 746px,661px 816px,637px 804px,568px 826px,560px 821px,512px 855px,539px 863px,485px 842px,379px 850px,367px 834px,372px 800px,315px 805px,283px 757px,292px 783px,260px 720px,238px 650px,258px 609px,222px 596px,241px 533px,237px 492px,234px 459px,249px 419px,333px 361px,367px 327px,328px 333px,386px 314px,454px 292px,498px 294px,555px 300px,540px 295px,588px 312px,650px 326px,643px 342px,675px 362px,745px 447px,771px 471px,760px 551px,747px 593px,743px 629px;
  }
  93.3% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-position: 773px 676px,767px 670px,743px 712px,667px 762px,706px 751px,662px 821px,638px 809px,568px 831px,560px 826px,512px 860px,540px 868px,485px 847px,378px 855px,367px 839px,371px 804px,314px 810px,282px 762px,291px 787px,259px 724px,236px 654px,255px 614px,220px 600px,238px 537px,235px 496px,232px 462px,246px 422px,331px 362px,364px 327px,326px 335px,384px 315px,453px 292px,498px 293px,556px 299px,541px 295px,590px 312px,652px 327px,646px 343px,678px 363px,747px 450px,774px 474px,763px 555px,749px 597px,745px 634px;
  }
  96.6% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-position: 774px 680px,768px 674px,744px 716px,668px 767px,707px 755px,662px 825px,639px 814px,569px 836px,561px 831px,512px 865px,540px 872px,485px 852px,378px 859px,366px 843px,371px 809px,313px 814px,281px 766px,290px 792px,257px 729px,235px 658px,253px 618px,218px 604px,236px 541px,233px 499px,229px 465px,244px 424px,328px 363px,362px 328px,324px 336px,382px 315px,452px 292px,498px 293px,558px 299px,541px 294px,592px 312px,654px 328px,648px 343px,680px 364px,750px 453px,776px 477px,765px 558px,751px 601px,747px 638px;
  }
  100% {

    background-size: 0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px,0px 0px;
    background-position: 775px 684px,769px 678px,745px 720px,669px 772px,708px 760px,663px 830px,639px 819px,569px 841px,561px 836px,512px 869px,540px 877px,485px 857px,378px 864px,366px 848px,370px 814px,313px 819px,280px 770px,290px 796px,256px 733px,233px 662px,251px 622px,216px 608px,233px 544px,230px 502px,227px 468px,242px 427px,325px 364px,360px 328px,322px 337px,380px 315px,452px 291px,498px 292px,559px 299px,542px 294px,594px 312px,656px 329px,651px 344px,683px 365px,753px 456px,778px 480px,768px 562px,753px 606px,749px 643px;
  }}
.particle-button.particles::after {
  left: 50%;
  transform: translateX(-50%) scale(0.5);
  top: -500px;
  min-width: 1000px;
  min-height: 1000px;
}
