//
// Pager
// --------------------------------------------------

// PAGER
.item-list {
  margin: 30px 0 20px;
  ul.pager {
    clear: both;
    font-size: 17px;
    margin: 0;
    padding: 0;
    text-align: center;
    span {
      display: none;
    }
    a {
      text-decoration: none;
    }
    li {
      display: inline;
      list-style: none;
      margin: 0;
      padding: 0;
      &.pager-current,
      a {
        background-color: transparent;
        border: none;
        color: @link-color;
        .inline-block();
        // height: 24px;
        line-height: 24px;
        padding: 2px;
        text-align: center;
        .border-radius(2px);
        .transition(all .2s ease);
      }
      &.pager-current {
        background-color: @color-tertiary;
        color: @white;
        .fontBold;
        min-width: 28px;
        padding-left: 4px;
        padding-right: 4px;
        // padding: 0;
        .lt-ie8 & {
          margin-right: 4px;
        }
      }
      a {
        &:hover {
          background-color: @color-tertiary;
          color: @white;
        }
      }
      &.pager-item {
        a {
          min-width: 30px;
          // padding: 0 1px;
        }
      }
      &.pager-first,
      &.pager-previous,
      &.pager-next,
      &.pager-last {
        a {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }
}

@media (min-width: @bp-small) {
  .item-list {
    margin: 45px 0 30px;
    ul.pager {
      span {
        display: inline;
      }
    }
  }
}