//
// Select
// --------------------------------------------------

// JCF SELECT
.jcf-form,
.form-type-select {
  color: @white;
  margin: 0 0 1rem;
  position: relative;
  .transition(all .25s ease-in-out);
  &:hover {
    cursor: pointer;
  }
  // Icon
  i {
    color: @color-secondary;
    font-size: 0.7777777778rem;
    left: 14px;
    position: absolute;
    top: 16px;
    .lt-ie9 & {
      display: none;
    }
  }
  // Select
  .jcf-select,
  select {
    background-color: @color-primary;
    display: block;
    .fontBold;
    font-size: 1rem;
    line-height: 1em;
    margin: 0 auto;
    padding: 13px 12px 14px 40px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  select {
    color: @black;
    margin: 12px 10px 11px 10px;
    padding: 0;
  }
  .form-type-select {
    .flex-align-items(center);
    .flex-display(flex);
    margin-bottom: 0.7222222222rem !important;
    width: 100%;
    label {
      font-size: 0.8888888889rem;
      margin-bottom: 0;
      order: 2;
    }
    .formSelect {
      .flex-order(1);
    }
    .no-flexbox & {
      display: block;
      .formSelect,
      label {
        display: inline-block;
        order: 1;
        vertical-align: middle;
      }
    }
  }
  .bg-primary & {
    .jcf-select,
    select {
      background-color: @color-quinary;
    }
  }
}
// Dropdown
.jcf-select-drop {
  background-color: @color-quinary;
  border: 1px solid @color-quinary;
  border-top: none;
  z-index: 999;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li:first-child,
    li:last-child {
      span {
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
    span {
      color: @white;
      display: block;
      font-size: 0.8888888889rem;
      line-height: 1.375em;
      padding: 7px 40px 7px 15px;
      .transition(all .25s ease-in-out);
      &:hover {
        background-color: @color-secondary;
        color: @color-quinary;
        cursor: pointer;
      }
      &.jcf-selected {
        background-color: @color-secondary;
        color: @color-quinary;
        .fontSemibold;
          &:hover {
          background-color: @color-secondary;
          color: @color-quinary;
          cursor: pointer;
        }
      }
    }
  }
  .jcf-list-content {
    display: block;
  }
}