//
// Media
// --------------------------------------------------

// TOKEN IMAGES D7
/*
@tokenImageGutter: 2%;

.tokenImage {
  display: block;
  margin: 6px auto 1.2em;
  width: 50%-@tokenImageGutter/2;
  // Image
  img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 100%;
  }
  // Caption
  .caption {
    color: @text-color;
    display: block;
    font-size: 14px;
    font-style: italic;
    line-height: 1.375em;
    padding: 3px 0;
    margin: 0 auto;
    text-align: left;
    a, a:hover {
      color: inherit;
    }
  }
  // Sizes
  &.mini {
    .clearfix;
    width: 80%;
  }
  &.small,
  &.medium,
  &.large,
  &.full {
    .clearfix;
    width: 100%;
  }
}

@media (min-width: @bp-small) {
  .tokenImage {
    float: left;
    margin: 6px 2.5*@tokenImageGutter 1.2em 0;
    &.mini,
    &.small {
      width: 55%-@tokenImageGutter/2;
    }
    &.medium {
      width: 60%-@tokenImageGutter/2;
    }
    &.large {
      width: 80%-@tokenImageGutter/2;
    }
    &.right {
      float: right;
      margin: 6px 0 1.2em 2.5*@tokenImageGutter;
    }
    &.center {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (min-width: @bp-medium) {
  .tokenImage {
    float: left;
    margin: 6px 2.5*@tokenImageGutter 1.2em 0;
    &.mini {
      width: 40%-@tokenImageGutter/2;
    }
    &.small {
      width: 50%-@tokenImageGutter/2;
    }
    &.large {
      width: 90%-@tokenImageGutter/2;
    }
  }
}

@media (min-width: @bp-large) {
  .tokenImage {
    float: left;
    margin: 6px 2.5*@tokenImageGutter 1.2em 0;
    &.mini {
      width: 30%-@tokenImageGutter/2;
    }
    &.small {
      width: 40%-@tokenImageGutter/2;
    }
    &.medium {
      width: 50%-@tokenImageGutter/2;
    }
    &.large {
      width: 70%-@tokenImageGutter/2;
    }
  }
}
*/

// TOKEN IMAGES D8

.articleContent {
  // Both
  figure,
  img[class^="inline-"],
  img[class*=" inline-"] {
    margin: 6px auto @base-line-height;
    &.align-left {
      float: left;
    }
    &.align-right {
      float: right;
    }
    &.align-center {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
    &.inline-mini {
    .clearfix;
      width: 80%;
    }
    &.inline-small,
    &.inline-medium,
    &.inline-large,
    &.inline-full {
      .clearfix;
      width: 100%;
    }
  }

  // Has caption
  figure {
    img {
      display: block;
      height: auto;
      margin: 0 auto;
      width: 100%;
    }
    figcaption {
      color: @color-secondary;
      display: block;
      .fontItalic;
      font-size: 0.8888888889rem;
      line-height: 1.375em;
      padding: 5px 0;
      margin: 0 auto;
      text-align: left;
      a, a:hover {
        color: inherit;
      }
    }
  }

  // Has no caption
  img[class^="inline-"],
  img[class*=" inline-"] {
    display: block;
    height: auto;
    width: 100%;
  }
}

@media (min-width: @bp-small) {
  .articleContent {
    figure,
    img[class^="inline-"],
    img[class*=" inline-"] {
      &.align-left {
        margin-right: @base-line-height;
      }
      &.align-right {
        margin-left: @base-line-height;
      }
      &.inline-mini,
      &.inline-small {
        width: 55%;
      }
      &.inline-medium {
        width: 60%;
      }
      &.inline-large {
        width: 80%;
      }
    }
  }
}

@media (min-width: @bp-medium) {
  .articleContent {
    figure,
    img[class^="inline-"],
    img[class*=" inline-"] {
      &.inline-mini {
        width: 40%;
      }
      &.inline-small {
        width: 50%;
      }
      &.inline-large {
        width: 80%;
      }
    }
  }
}

@media (min-width: @bp-large) {
  .articleContent {
    figure,
    img[class^="inline-"],
    img[class*=" inline-"] {
      &.inline-mini {
        width: 30%;
      }
      &.inline-small {
        width: 40%;
      }
      &.inline-medium {
        width: 50%;
      }
      &.inline-large {
        width: 70%;
      }
    }
  }
}