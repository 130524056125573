//
// Home
// --------------------------------------------------

.row-home {
  padding: 25px;
  h3 {
    color: @color-secondary;
  }
  &.row-primary {
    background-color: @color-primary;
    p {
      color: @white;
    }
  }
  &.row-secondary {
    background-color: @color-secondary;
  }
  &.row-tertiary {
    background-color: @color-tertiary;
  }
  &.row-quaternary {
    background-color: @color-quaternary;
  }
  &.row-quinary {
    background-color: @color-quinary;
  }
  &.row-white {
    background-color: @white;
  }
  .row-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
  .logo {
    margin-bottom: 35px;
    text-align: center;
  }
  .fast-button {
    .flex-align-items(center);
    background-color: @color-secondary;
    color: @white;
    .flex-display(flex);
    .fontBold;
    font-size: 1.1176470588rem;
    line-height: 1.2em;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    width: 100%;
    .border-radius(10px);
    .transition(all .25s ease);
    &:hover {
      background-color: #ff750c;
      text-decoration: none;
    }
    span {
      .flex(1 1 auto);
      padding: 13px 12px 14px;
    }
    span:last-child {
      background-color: #ff750c;
      .flex(0 1 auto);
      width: 33.4855403349%;
    }
    i {
      display: inline-block;
      font-size: 2.2rem;
      margin-right: 7px;
      vertical-align: middle;
    }
    b {
      display: inline-block;
      font-size: 0.8235294118rem;
      .fontRegular;
      vertical-align: middle;
      .translate(0, 3px);
    }
  }
  // INTRO
  &#row-intro {
    text-align: center;
    p {
      font-size: 1.2941176471rem;
      line-height: 1.5909090909em;
    }
  }
  // USP & DEVICES
  &#row-usp {
    padding-bottom: 0;
    .row-inner {
      max-width: none;
    }
    .devices {
      margin: 25px -25px 0;
      width: ~"calc(100% + 50px)";
      img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  // WORKING
  &#row-working {
    p {
      line-height: 1.4705882353em;
      &:last-of-type {
        margin-bottom: 1rem;
      }
      a {
        color: @white;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .btn-transparent {
      color: @white;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        color: @color-secondary;
      }
    }
  }
  // PARTICIPATE
  &#row-participate {
    p {
      line-height: 1.4705882353em;
    }
  }
  // INFO
  &#row-info {
    .app-stores {
      margin-bottom: 30px;
      text-align: center;
      a {
        display: inline-block;
        padding: 3px 8px;
        vertical-align: top;
      }
    }
    p {
      font-size: 0.8823529412rem;
      line-height: 1.6666666667em;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .logos {
      .flex-align-items(center);
      .flex-display(flex);
      .flex-wrap(wrap);
      margin: 0 -25px;
      a {
        display: block;
        padding: 25px;
        text-align: center;
        width: 100%;
        .opacity(0.3);
        .no-touchevents & {
          filter: grayscale(100%);
        }
        &:hover {
          .opacity(1);
          .no-touchevents & {
            filter: grayscale(0%);
          }
        }
      }
      img {
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
    }
  }
  // SHARE
  &#row-share {
    padding: 35px 25px;
    text-align: center;
    span,
    .social-links {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      color: @white;
      font-size: 15px;
      margin-right: 10px;
    }
    .social-links {
      .flex-align-items(center);
      display: inline-flex;
      white-space: nowrap;
    }
    a {
      color: @white;
      margin: 0 1px;
      padding: 5px;
      text-decoration: none;
    }
    .icon {
      .flex-align-items(center);
      .flex-display(flex);
      .flex-justify-content(center);
      height: 38px;
      width: 38px;
      .border-radius(38px);
    }
    .facebook .icon { background-color: @color-facebook; }
    .twitter .icon { background-color: @color-twitter; }
    .linkedin .icon { background-color: @color-linkedin; }
    .mail .icon { background-color: #ff6b00; }
  }
}

@media (min-width: 600px) {
  .row-home {
    // INFO
    &#row-info {
      .logos {
        //.flex-wrap(nowrap);
        margin: 0;
        padding: 0 25px;
        a {
          padding: 20px 25px;
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: @bp-small) {
  .row-home {
    padding: 45px 30px;
    // INTRO
    &#row-intro {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    // USP & DEVICES
    &#row-usp {
      padding-top: 30px;
      .devices {
        margin-left: -30px;
        margin-right: -30px;
        width: ~"calc(100% + 60px)";
      }
    }
    // SHARE
    &#row-share {
      padding: 55px 30px;
    }
  }
}