//
// Header
// --------------------------------------------------

.region-header {
  margin: 0 auto;
  max-width: 1024px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 50;
}

#header {
  background-color: #362146;
  padding: 9px 0;
  position: relative;
  &.no-padding {
    padding: 0;
  }
  .flexbox & {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .col-xs-12 {
    .flex-align-items(center);
    .flex-display(flex);
    .flex-justify-content(space-between);
    .no-flexbox & {
      display: block;
      .actions-wrapper {
        float: left;
        + .actions-wrapper {
          float: right;
        }
      }
    }
  }
  .actions-wrapper {
    .text {
      color: @white;
      display: none;
      margin-right: 10px;
    }
  }
}

@media (min-width: @bp-x-small) {
  #header {
    .actions-wrapper {
      .text {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}