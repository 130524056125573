//
// Modal
// --------------------------------------------------

body.modal-open {
  overflow: visible;
}

// MODAL OVERLAY
.modal-overlay {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  .opacity(0);
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: -1;
  .transition(all .4s ease);
  .lt-ie9 & {
    display: none !important;
    visibility: visible;
    .opacity(1);
  }
  &.modal-is-active {
    .opacity(1);
    visibility: visible;
    z-index: 1000;
    .lt-ie9 & {
      display: block !important;
    }
  }
  .no-rgba & {
    background: @black;
    .opacity(0.7);
  }
}

// MODAL ITSELF
.modal {
  display: block;
  height: auto;
  left: 0;
  margin: 0 auto 50px;
  max-width: 850px;
  min-width: 270px;
  padding: 0 15px;
  position: absolute;
  .opacity(0);
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1001;
  .backface-visibility(hidden);
  .box-sizing(border-box);
  -webkit-transform: scale(0.85) translate3d(0, 0, 0);
  -moz-transform: scale(0.85) translate3d(0, 0, 0);
  -o-transform: scale(0.85) translate3d(0, 0, 0);
  transform: scale(0.85) translate3d(0, 0, 0);
  -webkit-transition: opacity .4s ease, visibility .4s ease, -webkit-transform .4s ease;
  -moz-transition: opacity .4s ease, visibility .4s ease, -moz-transform .4s ease;
  -o-transition: opacity .4s ease, visibility .4s ease, -o-transform .4s ease;
  transition: opacity .4s ease, visibility .4s ease, transform .4s ease;
  .lt-ie9 & {
    display: none;
    visibility: visible;
    .opacity(1);
  }
  // Shown
  &.modal-is-active {
    .opacity(1);
    .scale(1);
    visibility: visible;
    z-index: 2000;
    .lt-ie9 & {
      display: block;
      height: 500px;
      margin-top: 65px;
    }
  }
  // Close button
  span.modal-close {
    cursor: pointer;
    color: #cccccc;
    display: block;
    height: 40px;
    margin: 0;
    position: absolute;
    right: 33px;
    text-align: center;
    top: 15px;
    width: 40px;
    z-index: 2001;
    .icon-close {
      .inline-block();
      font-size: 18px;
      line-height: 1em;
    }
  }
  .mainContent {
    background-color: @white;
    overflow: hidden;
    padding: 37px 45px 35px 30px;
    .box-shadow(0 2px 20px 8px rgba(0, 0, 0, 0.2));
    .articleContent {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: @bp-x-small) {
  .modal {
    padding: 0 25px;
  }
}

@media (min-width: @bp-small) {
  .modal {
    .mainContent {
      padding-left: 40px;
      padding-right: 60px;
    }
  }
}