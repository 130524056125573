//
// Mixins
// --------------------------------------------------

// BACKFACE VISIBILITY
.backface-visibility(@visibility){
  -webkit-backface-visibility: @visibility;
  -moz-backface-visibility: @visibility;
  backface-visibility: @visibility;
}

// BORDER RADIUS (SHORTHAND)
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  border-radius: @radius;
}

// BOX SHADOW
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
  box-shadow: @shadow;
}

// BOX SIZING
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
  -moz-box-sizing: @boxmodel;
  box-sizing: @boxmodel;
}

// CLEARFIX
.clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// DISPLAY INLINE-BLOCK FIX for IE7
.inline-block() {
  display: inline-block;
  .lt-ie8 & {
    display: inline;
    zoom: 1;
  }
}

// DISPLAY INLINE-BLOCK FIX with letter- and word-spacing
.spacing() {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  *word-spacing: -0.43em;
}
.spacing-normal() {
  letter-spacing: normal;
  word-spacing: normal;
}

// HYPHENS (experimental)
.hyphens(@mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: @mode;
  -moz-hyphens: @mode;
  -ms-hyphens: @mode; // IE10+
  -o-hyphens: @mode;
  hyphens: @mode;
}

// RESPONSIVE IMAGE
.img-responsive(@display: block) {
  display: @display;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// OPACITY
.opacity(@opacity) {
  opacity: @opacity;
  // IE8 filter
  @opacity-ie: (@opacity * 100);
  filter: ~"alpha(opacity=@{opacity-ie})";
}

// INPUT PLACEHOLDER
.placeholder(@color: @input-color-placeholder) {
  // Firefox
  &::-moz-placeholder {
    color: @color;
    opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { color: @color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: @color; } // Safari and Chrome
}



// TRANSFORMATIONS
.scale(@ratio) {
  -webkit-transform: scale(@ratio);
  -ms-transform: scale(@ratio); // IE9 only
  -o-transform: scale(@ratio);
  transform: scale(@ratio);
}
.scale(@ratioX; @ratioY) {
  -webkit-transform: scale(@ratioX, @ratioY);
  -ms-transform: scale(@ratioX, @ratioY); // IE9 only
  -o-transform: scale(@ratioX, @ratioY);
  transform: scale(@ratioX, @ratioY);
}
.scaleX(@ratio) {
  -webkit-transform: scaleX(@ratio);
  -ms-transform: scaleX(@ratio); // IE9 only
  -o-transform: scaleX(@ratio);
  transform: scaleX(@ratio);
}
.scaleY(@ratio) {
  -webkit-transform: scaleY(@ratio);
  -ms-transform: scaleY(@ratio); // IE9 only
  -o-transform: scaleY(@ratio);
  transform: scaleY(@ratio);
}
.skew(@x; @y) {
  -webkit-transform: skewX(@x) skewY(@y);
  -ms-transform: skewX(@x) skewY(@y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  -o-transform: skewX(@x) skewY(@y);
  transform: skewX(@x) skewY(@y);
}
.translate(@x; @y) {
  -webkit-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y); // IE9 only
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}
.translate3d(@x; @y; @z) {
  -webkit-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}
.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
  -ms-transform: rotate(@degrees); // IE9 only
  -o-transform: rotate(@degrees);
  transform: rotate(@degrees);
}
.rotateX(@degrees) {
  -webkit-transform: rotateX(@degrees);
  -ms-transform: rotateX(@degrees); // IE9 only
  -o-transform: rotateX(@degrees);
  transform: rotateX(@degrees);
}
.rotateY(@degrees) {
  -webkit-transform: rotateY(@degrees);
  -ms-transform: rotateY(@degrees); // IE9 only
  -o-transform: rotateY(@degrees);
  transform: rotateY(@degrees);
}
.perspective(@perspective) {
  -webkit-perspective: @perspective;
  -moz-perspective: @perspective;
  perspective: @perspective;
}
.perspective-origin(@perspective) {
  -webkit-perspective-origin: @perspective;
  -moz-perspective-origin: @perspective;
  perspective-origin: @perspective;
}
.transform-origin(@origin) {
  -webkit-transform-origin: @origin;
  -moz-transform-origin: @origin;
  -ms-transform-origin: @origin; // IE9 only
  transform-origin: @origin;
}


// TRANSITIONS
.transition(@transition) {
  -webkit-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}
.transition-property(@transition-property) {
  -webkit-transition-property: @transition-property;
  transition-property: @transition-property;
}
.transition-delay(@transition-delay) {
  -webkit-transition-delay: @transition-delay;
  transition-delay: @transition-delay;
}
.transition-duration(@transition-duration) {
  -webkit-transition-duration: @transition-duration;
  transition-duration: @transition-duration;
}
.transition-timing-function(@timing-function) {
  -webkit-transition-timing-function: @timing-function;
  transition-timing-function: @timing-function;
}
.transition-transform(@transition) {
  -webkit-transition: -webkit-transform @transition;
  -moz-transition: -moz-transform @transition;
  -o-transition: -o-transform @transition;
  transition: transform @transition;
}

// USER SELECT
.user-select(@select) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  -ms-user-select: @select; // IE10+
  user-select: @select;
}

// flexbox
.flex-display(@inline:false) {

  @box-display: ~`"@{inline}" === 'inline' ? 'box-inline' : 'box'`;
  @flex-display: ~`"@{inline}" === 'inline' ? 'inline-flex' : 'flex'`;

  display: ~"-webkit-@{box-display}";
  display: ~"-moz-@{box-display}";
  display: ~"-ms-@{flex-display}box";
  display: ~"-webkit-@{flex-display}";
  display: @flex-display;
}
// row, column
// true, false
.flex-direction(@direction:row) {

  @old-value: ~`"@{direction}".split('-')[0] === 'column' ? 'vertical' : 'horizontal'`;
  @reverse: ~`"@{direction}".indexOf('-') > -1 ? 'reverse' : false `;

  .box-direction(@reverse) when (@reverse = reverse) {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
  };

  -webkit-box-orient: @old-value;
  -moz-box-orient: @old-value;
  -ms-flex-direction: @direction;
  -webkit-flex-direction: @direction;
  flex-direction: @direction;

  .box-direction(@reverse);
}
// flex-start, flex-end, center, baseline, stretch
.flex-align-items(@style:stretch) {

  @old-style: ~`(function() { var style = "@{style}"; return style.indexOf('-') > -1 ? style.split('-')[1] : style; })()`;

  -webkit-box-align: @old-style;
  -moz-box-align: @old-style;
  -ms-flex-align: @old-style;
  -webkit-align-items: @style;
  align-items: @style;
}
// flex-start, flex-end, center, space-between, space-around
.flex-justify-content(@justify:flex-start) {

  @ms-justify: ~`(function() { var justify = "@{justify}"; switch(justify) { case 'flex-start': return 'start'; case 'flex-end': return 'end'; case 'space-between': return 'justify'; case 'space-around': return 'distribute'; } return justify; })()`;
  @old-justify: ~`"@{ms-justify}" === 'distribute' ? 'justify' : "@{ms-justify}"`;

  -webkit-box-pack:@old-justify;
  -moz-box-pack:@old-justify;
  -ms-flex-pack:@ms-justify;
  -webkit-justify-content: @justify;
  justify-content: @justify;
}
// nowrap, wrap, wrap-reverse
.flex-wrap(@wrap:nowrap) {

  @old-wrap: ~`"@{wrap}" === 'wrap' ? 'multiple' : 'single'`;

  -webkit-box-lines: @old-wrap;
  -moz-box-lines: @old-wrap;
  -ms-flex-wrap:@wrap;
  -webkit-flex-wrap:@wrap;
  flex-wrap:@wrap;
}
// stretch, flex-start, flex-end, center, space-between, space-around
.flex-align-content(@align:stretch) {

  @ms-align: ~`(function() { var justify = "@{align}"; switch(justify) { case 'flex-start': return 'start'; case 'flex-end': return 'end'; case 'space-between': return 'justify'; case 'space-around': return 'distribute'; } return justify; })()`;

  -ms-flex-line-pack: @ms-align;
  -webkit-align-content: @align;
  align-content: @align;
}

// This is only a shortcut for the new syntax. We’re expanding it here for ease.
.flex-flow(@direction:row, @wrap:nowrap) {

  .flex-direction(@direction);
  .flex-wrap(@wrap);
}

.flex-order(@order:0) {
  -webkit-box-ordinal-group: @order;
  -moz-box-ordinal-group: @order;
  -ms-flex-order: @order;
  -webkit-order: @order;
  order: @order;
}
// flex-start, flex-end, center, baseline, stretch, auto
.flex-align-self(@style:stretch) {

  @old-style: ~`(function() { var style = "@{style}"; return style.indexOf('-') > -1 ? style.split('-')[1] : style; })()`;

  -webkit-box-align: @old-style;
  -moz-box-align: @old-style;
  -ms-flex-item-align: @old-style;
  -webkit-align-self: @style;
  align-self: @style;
}

.flex(@number:initial) {
  -webkit-box-flex:@number;
  -moz-box-flex:@number;
  -webkit-flex:@number;
  -ms-flex:@number;
  flex:@number;
}