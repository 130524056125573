html, body.cke_editable, html.cke_panel_container body {
  background-color: @color-tertiary;
}

body.cke_editable {
  background-color: @white;
  max-width: 724px;
  margin: 0 auto;
  padding: 10px;
  height: auto;
  .clearfix;

  .caption {
    width: 100%;
    > * {
      width: 100%;
    }
  }

  // Hide resizers.
  .cke_widget_wrapper .cke_image_resizer {
    display: none;
  }

  .cke_widget_wrapper {
    &.align-left {
      margin-right: 2em;
    }
    &.align-right {
      margin-left: 2em;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.334em;

    &[data-responsive-image-style=token_small] {
      width: 13vw;
    }
    &[data-responsive-image-style=token_medium] {
      width: 28vw;
    }
    &[data-responsive-image-style=token_full] {
      width: 100%;
    }
  }

  figure {
    img {
      margin-bottom: 15px;
    }
    margin-bottom: 1.334em;

    figcaption {
      font-size: 15px;
      font-style: italic;
      line-height: 17 / 15;
      color: lighten(@text-color, 40%);
    }
  }
}
