//
// Progress
// --------------------------------------------------

.progress {
  background-color: @color-quinary;
  height: 12px;
  margin-bottom: 0;
  .border-radius(0);
  .box-shadow(none);
  .progress-bar {
    background-color: @color-secondary;
    .box-shadow(none);
  }
}

@media (min-width: @bp-small) {
  .progress {
    height: 20px;
  }
}